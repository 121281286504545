
























































































































































































.el-form-item__content {
  display: flex;
  align-items: center;
  
}
.el-form-item__content .img-el-upload /deep/.el-upload {
  margin-left: 15px;
  width: 30px;
  height: 30px !important;
}
